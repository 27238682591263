import { css, keyframes } from '@emotion/react';

const ANIMATION_DURATION = 1.5; //s

const starKeyframes = keyframes`
0% {
  opacity: 0;
  transform: translateY(10px);
}
20% {
  opacity: 0.9;
}
40% {
  opacity: 1;
}
60% {
  opacity: 0.9;
}
80% {
  opacity: 0.5;
}
100% {
  opacity: 0;
  transform: translateY(0);
}
`;

export const starAnimation = css`
  animation: ${starKeyframes} ${ANIMATION_DURATION}s ease;

  @media (prefers-reduced-motion: reduce) {
    & {
      opacity: 0;
      transform: translateY(0);
    }
  }
`;
