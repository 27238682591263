/* storybook-check-ignore */
import { css } from '@emotion/react';
import { Box } from '@opendoor/bricks/core';

import { ResponsivePicture } from 'components/exclusives/ResponsivePicture';

import { AnimatedListingCardStack } from './AnimatedListingCardStack';

// Card shuffling animation for the buyer landing page hero.
// Animation loops infinitely but does not play if user has reduce motion on.
export const BuyerHeroAnimation = () => {
  return (
    <Box
      aria-label="Hero image of listing cards showing a homes for sale."
      position="relative"
      css={css`
        @media (prefers-reduced-motion: reduce) {
          & * {
            animation: none !important;
            transition-duration: 0s !important;
          }
        }
      `}
    >
      <ResponsivePicture
        alt=""
        justifySelf={['center', null, 'end']}
        maxW={[null, null, '70%']}
        width={['100vw', null, 'unset']}
        pictureProps={{ style: { display: 'grid' } }}
        src="https://images.opendoor.com/source/s3/imgdrop-production/2024-02-1707154920433-93097.png?preset=square-2048"
      />
      <AnimatedListingCardStack />
    </Box>
  );
};
