/* storybook-check-ignore */
import Slider, { Settings as SliderSettings } from 'react-slick';

import { Box, BoxProps, Button, Icon, Link } from '@opendoor/bricks/core';
import SelectiveSpritesheet from '@opendoor/bricks/core/Icon/SelectiveSpritesheet';
import ChevronLeft from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronLeft';
import ChevronRight from '@opendoor/bricks/core/Icon/SpritesheetIcons/ChevronRight';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { ListingWithComputedProperties } from '../../../declarations/exclusives/listing';
import { getListingThumbnail } from '../../../helpers/exclusives/listing';
import { GalleryCardv2 } from './GalleryCardv2/GalleryCardv2';

export type BaseGallerySliderProps = {
  sliderSettings?: SliderSettings;
  appPrefix?: 'exclusive-offers' | 'exclusive';
  analyticsPrefix?: string;
  'aria-label'?: string;
  children: JSX.Element[];
};

export const DEFAULT_SLIDER_SETTINGS: SliderSettings = {
  dots: false,
  infinite: true,
  speed: 200,
  className: 'center',
  centerMode: true,
  centerPadding: '60px',
  slidesToShow: 4,
  autoplay: false,
  draggable: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        speed: 300,
        autoplay: false,
        draggable: true,
        slidesToShow: 3,
        className: 'center',
        centerMode: true,
        centerPadding: '60px',
      },
    },
    {
      breakpoint: 900,
      settings: {
        speed: 300,
        autoplay: false,
        draggable: true,
        slidesToShow: 2,
        className: 'center',
        centerMode: true,
        centerPadding: '60px',
      },
    },
    {
      breakpoint: 600,
      settings: {
        speed: 300,
        autoplay: false,
        draggable: true,
        slidesToShow: 1,
        className: 'center',
        centerMode: true,
        centerPadding: '60px',
      },
    },
  ],
};

export const BaseGallerySlider: React.FC<BaseGallerySliderProps & BoxProps> = ({
  sliderSettings,
  appPrefix,
  analyticsPrefix,
  children,
  'aria-label': ariaLabel,
  ...boxProps
}) => {
  if (children.length === 1) {
    return (
      <Box
        display="grid"
        gridTemplateColumns={[
          null,
          'repeat(2, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(4, 1fr)',
        ]}
        gridGap="2rem"
        paddingY="1.5rem"
        paddingX="0.5rem"
        w="100%"
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      overflow="hidden"
      position="relative"
      aria-label={ariaLabel}
      sx={{
        '.slick-dots': {
          position: 'unset',
          li: {
            mx: 0,
            button: {
              _before: { fontSize: 's000' },
            },
          },
        },
      }}
      {...boxProps}
    >
      <Slider
        {...DEFAULT_SLIDER_SETTINGS}
        {...sliderSettings}
        nextArrow={
          <Arrow direction="Next" analyticsPrefix={analyticsPrefix} appPrefix={appPrefix} />
        }
        prevArrow={
          <Arrow direction="Previous" analyticsPrefix={analyticsPrefix} appPrefix={appPrefix} />
        }
      >
        {children}
      </Slider>
    </Box>
  );
};

type GalleryListingsProps = Pick<BaseGallerySliderProps, 'sliderSettings' | 'analyticsPrefix'> &
  BoxProps & {
    listings: ListingWithComputedProperties[];
    source?: 'gallery' | 'pdp' | 'saved-homes';
    onNotifiableEventSuccess?: (message?: string) => void;
    onNotifiableEventError?: (message?: string) => void;
    shouldHyperlinkCards?: boolean;
  };

const GallerySlider: React.FC<GalleryListingsProps> = ({
  listings,
  source,
  onNotifiableEventError,
  onNotifiableEventSuccess,
  shouldHyperlinkCards = true,
  ...props
}) => (
  <BaseGallerySlider {...props}>
    {listings.map((detail) => (
      <Box key={`${detail.id}`} px={2} pb={64}>
        <Link
          href={
            shouldHyperlinkCards
              ? `/exclusives/homes/${encodeURIComponent(detail.slug)}`
              : undefined
          }
          aria-label={null}
          analyticsName={`cosmos-exclusive-slider-gallery${
            props?.analyticsPrefix ? '-' + props.analyticsPrefix : ''
          }-card`}
        >
          <Box
            backgroundColor="neutrals0"
            border="1px solid rgba(0, 0, 0, 0.04)"
            borderRadius="roundedSmooth"
            boxShadow="0px 12px 24px 0px rgba(0, 0, 0, 0.08)"
            padding={5}
          >
            <GalleryCardv2
              thumbnailUrl={getListingThumbnail(detail, '_375x250')}
              listing={detail}
              source={source}
              onNotifiableEventSuccess={onNotifiableEventSuccess}
              onNotifiableEventError={onNotifiableEventError}
            />
          </Box>
        </Link>
      </Box>
    ))}
  </BaseGallerySlider>
);

const Arrow: React.FC<
  BoxProps &
    Pick<BaseGallerySliderProps, 'analyticsPrefix' | 'appPrefix'> & {
      direction: 'Previous' | 'Next';
    }
> = ({ direction, analyticsPrefix, appPrefix, ...props }) => (
  <Box
    zIndex={4}
    top="40%"
    position="absolute"
    _before={{ display: 'none' }}
    left={direction === 'Previous' ? [7, null, null, 9] : 'initial'}
    right={direction === 'Next' ? [9, null, null, '56px'] : 'initial'}
    {...props}
  >
    <SelectiveSpritesheet icons={[ChevronLeft, ChevronRight]} />
    <Button
      type="button"
      variant="icon"
      aria-label={`${direction} slide`}
      onClick={() => {}}
      analyticsName={`cosmos-${appPrefix}-slider-gallery${
        analyticsPrefix ? '-' + analyticsPrefix : undefined
      }-${direction === 'Previous' ? 'left' : 'right'}`}
    >
      <Icon name={direction === 'Previous' ? 'chevron-left' : 'chevron-right'} />
    </Button>
  </Box>
);

export default GallerySlider;
