import { css, keyframes } from '@emotion/react';

const ANIMATION_DELAY = 1; //s
const ANIMATION_DURATION = 1.5; //s

// Keyframes for the card shuffling animation
const KEYFRAMES = {
  topCard: keyframes`
  0%, 50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-16px) rotate(-5deg);
  }
  `,
  middleCard: keyframes`
  0% {
    z-index: 2;
    top: 0;
    left: 0;
  }
  50%, 100% {
    z-index: 2;
    top: 8px;
    left: 8px;
  }
  `,
  bottomCard: keyframes`
  0% {
    z-index: 1;
    top: 0;
    left: 0;
  }
  50% {
    transform: translateX(0px);
    z-index: 1;
    top: 16px;
    left: 16px;
  }
  100% {
    transform: translateX(32px) rotate(5deg);
  }
  `,
};

export const ANIMATIONS = {
  topCard: css`
    animation: ${KEYFRAMES.topCard} ${ANIMATION_DURATION}s ease ${ANIMATION_DELAY}s;
    animation-fill-mode: forwards;

    @media (prefers-reduced-motion: reduce) {
      & {
        transform: translateX(-32px) rotate(-10deg);
      }
    }
  `,
  middleCard: css`
    animation: ${KEYFRAMES.middleCard} ${ANIMATION_DURATION}s ease ${ANIMATION_DELAY}s;
    animation-fill-mode: forwards;

    @media (prefers-reduced-motion: reduce) {
      & {
        z-index: 2;
        top: 2%;
        left: 2%;
      }
    }
  `,
  bottomCard: css`
    animation: ${KEYFRAMES.bottomCard} ${ANIMATION_DURATION}s ease ${ANIMATION_DELAY}s;
    animation-fill-mode: forwards;

    @media (prefers-reduced-motion: reduce) {
      & {
        transform: translateX(32px) rotate(10deg);
      }
    }
  `,
};
