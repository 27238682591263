// storybook-check-ignore
import React, { useRef } from 'react';

import { css } from '@emotion/react';
import { Box, Flex } from '@opendoor/bricks/core';

import LightningIcon from 'components/exclusives/icons/LightningIcon';
import { ResponsivePicture } from 'components/exclusives/ResponsivePicture';
import { useInViewAfterLoad } from 'components/shared/hooks/useInView';

import { starAnimation } from './animations';

export const SelfTourSectionAnimation: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inView } = useInViewAfterLoad({ ref });

  return (
    <Flex
      aria-label="Visual depicting the mobile view of scheduling an instant self tour for an Opendoor Exclusive home."
      alignItems="center"
      css={css`
        @media (prefers-reduced-motion: reduce) {
          & * {
            animation: none !important;
            transition-duration: 0s !important;
          }
        }
      `}
      justifyContent="center"
      position="relative"
      flexDirection="column"
    >
      <ResponsivePicture
        alt=""
        src="https://images.opendoor.com/source/s3/imgdrop-production/2024-07-1722373142966-80881.png?preset=square-2048"
        maxWidth={['100%', null, 353]}
      />
      <Flex
        alignItems="center"
        aria-hidden
        //@ts-expect-error custom color for instant tour badge
        backgroundColor="#EBF5EE"
        borderRadius="roundedSquare"
        color="success500"
        fontWeight="medium"
        height="32px"
        justifyContent="center"
        letterSpacing="-2.4"
        lineHeight="130"
        position="absolute"
        right="10%"
        top="53%"
        width="92px"
      >
        <ResponsivePicture
          alt=""
          css={css`
            ${starAnimation}
            animation-play-state: ${inView ? 'running' : 'paused'};
          `}
          src="https://images.opendoor.com/source/s3/imgdrop-production/2023-11-1699762575704-54568.png?preset=square-2048"
          left={0}
          position="absolute"
          top="-50%"
          width="100%"
          opacity={0}
        />
        <LightningIcon />
        Instant
      </Flex>
      {/* This Box is just used to activate the animation after the entire component is scrolled into view*/}
      <Box aria-hidden ref={ref} />
    </Flex>
  );
};
