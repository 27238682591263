import { css, keyframes } from '@emotion/react';

const ANIMATION_DELAY = 0.5; //s
const ANIMATION_DURATION = 1; //s

// Keyframes for strikethrough and fade up animations
const KEYFRAMES = {
  estimatedRetailPriceColorChange: keyframes`
  from {
    color: var(--colors-neutrals100);
  }
  to {
    color: var(--colors-warmgrey800);
  }
  `,
  estimatedRetailPriceKeyStrikethrough: keyframes`
  from {
    width: 0;
  }
  to {
    width: 180px;
  }
  `,
  opendoorPrice: keyframes`
  from {
    opacity: 0;
    transform: translateY(50%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
  `,
};

export const ANIMATIONS = {
  estimatedRetailPriceColorChange: css`
    animation: ${KEYFRAMES.estimatedRetailPriceColorChange} ${ANIMATION_DURATION}s ease
      ${ANIMATION_DELAY}s;
    animation-fill-mode: forwards;

    @media (prefers-reduced-motion: reduce) {
      & {
        color: var(--colors-warmgrey800);
      }
    }
  `,
  estimatedRetailPriceStrikethrough: css`
    animation: ${KEYFRAMES.estimatedRetailPriceKeyStrikethrough} ${ANIMATION_DURATION}s ease
      ${ANIMATION_DELAY}s;
    animation-fill-mode: forwards;

    @media (prefers-reduced-motion: reduce) {
      & {
        width: 180px;
      }
    }
  `,
  opendoorPrice: css`
    animation: ${KEYFRAMES.opendoorPrice} ${ANIMATION_DURATION}s ease ${ANIMATION_DELAY}s;
    animation-fill-mode: forwards;

    @media (prefers-reduced-motion: reduce) {
      & {
        opacity: 1;
        transform: translateY(0%);
      }
    }
  `,
};
