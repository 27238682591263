/* storybook-check-ignore */
import { BoxProps, Flex, Link, Text } from '@opendoor/bricks/core';

export default function ErrorOnListingsFetchBanner(boxProps: BoxProps) {
  return (
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      background="terracotta50"
      borderRadius="roundedSquare"
      bg="#EEF4FA"
      width="100%"
      py="5rem"
      mb="1rem"
      px="1.5rem"
      {...boxProps}
    >
      <Flex
        width="auto"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gridGap="0.875rem"
      >
        <Text fontSize="s1" fontWeight="semibold" textAlign="center">
          Looks like something went wrong! We’re working on it, please check back later or call us
          at{' '}
          <Link
            aria-label={null}
            analyticsName="cosmos-exclusives-error-on-listings-fetch-banner-support-phone-link"
            href="tel:(512)706-9938"
          >
            (512) 706-9938
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
}
