// storybook-check-ignore
import { useRef } from 'react';

import { css } from '@emotion/react';
import { Box, Flex, Text } from '@opendoor/bricks/core';

import { ResponsivePicture } from 'components/exclusives/ResponsivePicture';
import { useInViewAfterLoad } from 'components/shared/hooks/useInView';

import { ANIMATIONS } from './animations';

/* 
   Animation to highlight off market pricing.
   Strikesthrough estimated retail price and fades in Opendoor price.
   Animation plays one time when the component first comes into view .
*/
export const OffMarketPricesSectionAnimation = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { inView } = useInViewAfterLoad({ ref });

  return (
    <Flex
      aria-label="Visual showing an estimated retail price strikedthrough and highlighting a lower Opendoor price. Visual also highlights lower fees with Opendoor."
      css={css`
        @media (prefers-reduced-motion: reduce) {
          & * {
            animation: none !important;
            transition-duration: 0s !important;
          }
        }
      `}
      position="relative"
      flexDirection="column"
      maxWidth={[311, null, 353]}
    >
      <ResponsivePicture
        alt=""
        src="https://images.opendoor.com/source/s3/imgdrop-production/2024-02-1707179386197-71386.png?preset=square-2048"
        maxWidth={['100%', null, 353]}
      />
      <Flex
        aria-hidden
        flexDirection="column"
        left="36px"
        position="absolute"
        top="140px"
        width="78%"
      >
        <Text
          css={css`
            ${ANIMATIONS.estimatedRetailPriceColorChange}
            animation-play-state: ${inView ? 'running' : 'paused'};
          `}
          fontSize="20px"
          fontWeight="medium"
          lineHeight="120"
          letterSpacing="-2.4"
        >
          Estimated retail price
        </Text>
        <Box
          css={css`
            ${ANIMATIONS.estimatedRetailPriceColorChange}
            animation-play-state: ${inView ? 'running' : 'paused'};
          `}
          fontSize="40px"
          lineHeight="110"
          letterSpacing="-3.4"
          marginTop="8px"
        >
          $423,000
          <Box
            as="hr"
            position="absolute"
            border="1px solid var(--colors-warmgrey800)"
            width={0}
            top="34px"
            css={css`
              ${ANIMATIONS.estimatedRetailPriceStrikethrough}
              animation-play-state: ${inView ? 'running' : 'paused'};
            `}
          />
        </Box>
        <Flex
          flexDirection="column"
          css={css`
            ${ANIMATIONS.opendoorPrice}
            animation-play-state: ${inView ? 'running' : 'paused'};
          `}
          opacity={0}
        >
          <Box as="hr" marginTop="34px" border="1px solid var(--colors-warmgrey300)" width="100%" />
          <Text
            fontSize="20px"
            fontWeight="medium"
            lineHeight="120"
            letterSpacing="-2.4"
            marginTop="28px"
          >
            Opendoor Exclusive price
          </Text>
          <Text color="brandBlue600" fontSize="40px" fontWeight="medium" letterSpacing="-3.4">
            $387,000
          </Text>
          <Flex
            flexDirection="column"
            width="100%"
            height="164px"
            backgroundColor="warmgrey100"
            border="1px solid var(--colors-warmgrey200)"
            borderRadius="semiRounded"
            padding="18px 20px"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Box flexGrow={2}>
                <Box
                  as="hr"
                  width="70%"
                  border="8px solid var(--colors-warmgrey300)"
                  borderRadius="roundedSmooth"
                  margin={0}
                  height="8px"
                />
              </Box>
              <Text
                marginRight="18px"
                textDecoration="line-through"
                lineHeight="120"
                letterSpacing="-2.4"
              >
                3%
              </Text>
              <Text color="success500" lineHeight="120" fontWeight="medium" letterSpacing="-2.4">
                0%
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" marginTop="16px">
              <Box flexGrow={2}>
                <Box
                  as="hr"
                  width="60%"
                  border="8px solid var(--colors-warmgrey300)"
                  borderRadius="roundedSmooth"
                  margin={0}
                  height="8px"
                />
              </Box>
              <Text
                marginRight="10px"
                textDecoration="line-through"
                lineHeight="120"
                letterSpacing="-2.4"
              >
                $1200
              </Text>
              <Text color="success500" lineHeight="120" fontWeight="medium" letterSpacing="-2.4">
                Free
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" marginTop="16px">
              <Box flexGrow={2}>
                <Box
                  as="hr"
                  width="65%"
                  border="8px solid var(--colors-warmgrey300)"
                  borderRadius="roundedSmooth"
                  margin={0}
                  height="8px"
                />
              </Box>
              <Text
                marginRight="10px"
                textDecoration="line-through"
                lineHeight="120"
                letterSpacing="-2.4"
              >
                $500
              </Text>
              <Text color="success500" lineHeight="120" fontWeight="medium" letterSpacing="-2.4">
                Free
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="space-between" marginTop="16px">
              <Box flexGrow={2}>
                <Box
                  as="hr"
                  width="60%"
                  border="8px solid var(--colors-warmgrey300)"
                  borderRadius="roundedSmooth"
                  margin={0}
                  height="8px"
                />
              </Box>
              <Text color="success500" lineHeight="120" fontWeight="medium" letterSpacing="-2.4">
                Free
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* This Box is just used to activate the animation after the entire component is scrolled into view*/}
      <Box aria-hidden ref={ref} />
    </Flex>
  );
};
