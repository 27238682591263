// storybook-check-ignore
import React from 'react';

import { Box, BoxProps, Flex } from '@opendoor/bricks/core';

import Crown from 'components/exclusives/icons/Crown';
import { ResponsivePicture } from 'components/exclusives/ResponsivePicture';

import { ANIMATIONS } from './animations';

type DisplayListingCardProps = BoxProps & {
  estimatedListingPrice: string;
  imageSrc: string;
  price: string;
  subtitle: string;
};

const DisplayListingCard: React.FC<DisplayListingCardProps> = ({
  estimatedListingPrice,
  imageSrc,
  price,
  subtitle,
  ...boxProps
}) => {
  return (
    <Box
      backgroundColor="neutrals0"
      borderRadius="roundedSmooth"
      boxShadow="0px 12px 24px 0px rgba(0, 0, 0, 0.08);"
      height={['60%', null, '55%']}
      padding={['14px', null, '16px']}
      position="absolute"
      width={['calc(100% - 96px)', null, '50%']}
      mx="40px"
      my={['24px', null, '15%']}
      minWidth={['180px', null, '300px']}
      minHeight={['170px', null, '270px']}
      {...boxProps}
    >
      <Box height="75%" position="relative">
        <ResponsivePicture
          alt=""
          borderRadius={['semiRounded', null, 'roundedSmooth']}
          height="100%"
          src={imageSrc}
          objectFit="cover"
        />
        <Flex position="absolute" top="8px" left="8px" gap="4px">
          <Flex
            alignContent="center"
            backgroundColor="neutrals0"
            //@ts-expect-error custom borderRadius for badge
            borderRadius="12px"
            fontSize={['12px', null, '14px']}
            fontWeight="medium"
            letterSpacing="-1.4"
            lineHeight="140"
            padding="6px 12px 4px 12px"
          >
            Opendoor Only
          </Flex>
          <Flex
            alignContent="center"
            background="linear-gradient(87deg, #CA8D18 12.8%, #ECAC08 89.6%)"
            //@ts-expect-error custom borderRadius for badge
            borderRadius="12px"
            color="neutrals0"
            fontSize={['12px', null, '14px']}
            fontWeight="medium"
            gap="4px"
            letterSpacing="-1.4"
            lineHeight="140"
            padding="6px 12px 4px 12px"
          >
            <Crown size={16} />
            Early access
          </Flex>
        </Flex>
      </Box>
      <Flex flexDirection="column">
        <Flex
          alignItems="center"
          color="brandBlue600"
          fontSize="24px"
          fontWeight="medium"
          letterSpacing="-2.8"
          lineHeight="112"
          marginTop="16px"
        >
          {price}
          <Box
            color="warmgrey700"
            fontSize="20px"
            letterSpacing="-2.4"
            lineHeight="112"
            marginLeft="12px"
            textDecoration="line-through"
            fontWeight="regular"
          >
            {estimatedListingPrice}
          </Box>
        </Flex>
        <Box fontSize="18px" letterSpacing="-2.4" lineHeight="120" marginTop="4px">
          {subtitle}
        </Box>
      </Flex>
    </Box>
  );
};

export const AnimatedListingCardStack = () => {
  return (
    <Box aria-hidden height="100%" position="absolute" top={0} left={0} width="100%">
      <DisplayListingCard
        css={ANIMATIONS.topCard}
        estimatedListingPrice="$422,000"
        imageSrc="https://images.opendoor.com/source/s3/imgdrop-production/2024-02-1707755379776-47847.png?preset=square-2048"
        left={0}
        price="$370,000"
        subtitle="Carrollton, TX · 5bd / 3ba"
        top={0}
        zIndex={3}
      />
      <DisplayListingCard
        css={ANIMATIONS.middleCard}
        estimatedListingPrice="$422,000"
        imageSrc="https://images.opendoor.com/source/s3/imgdrop-production/2024-02-1707755455783-04939.png?preset=square-2048"
        left={0}
        price="$370,000"
        subtitle="Carrollton, TX · 4bd / 2ba"
        top={0}
        zIndex={2}
      />
      <DisplayListingCard
        css={ANIMATIONS.bottomCard}
        estimatedListingPrice="$422,000"
        imageSrc="https://images.opendoor.com/source/s3/imgdrop-production/2024-02-1707756505531-23328.png?preset=square-2048"
        left={0}
        price="$370,000"
        subtitle="Carrollton, TX · 5bd / 3ba"
        top={0}
        zIndex={1}
      />
    </Box>
  );
};
