/* storybook-check-ignore */
import { RefObject, useEffect, useState } from 'react';
import { IntersectionOptions, observe } from 'react-intersection-observer';

import { useOnLoad } from '../useOnLoad';

/**
 * In most cases, the "useInView" helper doesn't need to attach and start
 * tracking until after the page load has already happened.
 */
export const useInViewAfterLoad = (
  opts: Omit<IntersectionOptions, 'delay' | 'trackVisibility'> & { ref: RefObject<HTMLDivElement> },
) => {
  const afterLoad = useOnLoad();
  const [{ inView, entry }, setState] = useState<{
    inView: boolean;
    entry?: IntersectionObserverEntry;
  }>({
    inView: opts?.initialInView ?? false,
    entry: undefined,
  });
  // Use an existing ref (passed in) instead of creating it internally
  // which avoids the bad behavior of the "useInView" hook from the
  // react-intersection-observer which re-renders multiple times
  useEffect(() => {
    if (!afterLoad || !opts.ref.current || opts.skip) {
      return;
    }
    let unobserve: (() => void) | undefined = observe(
      opts.ref.current,
      (inView, entry) => {
        setState({
          inView,
          entry,
        });

        if (entry.isIntersecting && opts.triggerOnce && unobserve) {
          // If it should only trigger once, unobserve the element after it's inView
          unobserve();
          unobserve = undefined;
        }
      },
      {
        root: opts.root,
        rootMargin: opts.rootMargin,
        threshold: opts.threshold,
      },
      opts.fallbackInView,
    );

    return () => {
      if (unobserve) {
        unobserve();
      }
    };
  }, [
    // If the threshold is an array, convert it to a string, so it won't change between renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Array.isArray(opts.threshold) ? opts.threshold.toString() : opts.threshold,
    opts.ref,
    opts.root,
    opts.rootMargin,
    opts.triggerOnce,
    opts.skip,
    opts.fallbackInView,
    afterLoad,
  ]);
  return { inView, entry };
};
