/* storybook-check-ignore */
export default function LightningIcon({ color = 'currentColor', size = '16' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.8 1C8.70425 1.00011 8.6103 1.02624 8.52801 1.07565C8.44571 1.12505 8.37811 1.19592 8.33229 1.2808L4.09271 8.23347V8.23453C4.0322 8.3241 3.99989 8.43004 4 8.53846C4 8.68127 4.05619 8.81823 4.15621 8.91921C4.25623 9.02019 4.39188 9.07692 4.53333 9.07692H7.46667L6.67708 14.3543C6.67007 14.3896 6.66658 14.4255 6.66667 14.4615C6.66667 14.6043 6.72286 14.7413 6.82288 14.8423C6.9229 14.9433 7.05855 15 7.2 15C7.29691 14.9998 7.39195 14.973 7.47491 14.9225C7.55786 14.8719 7.6256 14.7994 7.67083 14.7129H7.67188L11.8969 7.77915L11.8917 7.76968C11.9548 7.68122 12 7.57882 12 7.46154C12 7.16431 11.7611 6.92308 11.4667 6.92308H8.53333L9.30938 1.69727C9.32518 1.64584 9.33326 1.59231 9.33333 1.53846C9.33333 1.39565 9.27714 1.25869 9.17712 1.15771C9.07711 1.05673 8.94145 1 8.8 1Z"
        fill={color}
      />
    </svg>
  );
}
