// storybook-check-ignore
import React, { useEffect } from 'react';

import { Box, Flex, Text } from '@opendoor/bricks/core';
import useMedia from '@opendoor/bricks/hooks/useMedia';
import Aos from 'aos';

import StarFourWay from 'components/exclusives/icons/StarFourWay';
import { ResponsivePicture } from 'components/exclusives/ResponsivePicture';

/* 
   Animation to highlight early access notifications.
   New map pin appears and banner fades up from the bottom.
   Animation plays one time when the component first comes into view.
*/
export const EarlyAccessSectionAnimation: React.FC = () => {
  const prefersReducedMotion = useMedia('(prefers-reduced-motion: reduce)');

  useEffect(() => {
    Aos.init({ disable: prefersReducedMotion, once: true });
  }, []);

  return (
    <Flex
      alignItems="center"
      aria-label={
        'Visual depicting the mobile view of browsing a map of homes for sale. Visual shows a new exclusive home appearing on the map and a notification banner popping up with details about the home.'
      }
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <ResponsivePicture
        alt=""
        src="https://images.opendoor.com/source/s3/imgdrop-production/2024-02-1707174193604-70899.png?preset=square-2048"
        maxWidth={['100%', null, 353]}
      />
      <Flex
        alignItems="center"
        aria-hidden
        backgroundColor="brandBlue600"
        border="1px solid"
        borderColor="blue700"
        // @ts-expect-error - custom borderRadius for map pin
        borderRadius="10px"
        data-aos="zoom-in"
        data-aos-anchor="#exclusive-map-pin-animate"
        data-aos-anchor-placement="top-bottom"
        height="32px"
        justifyContent="center"
        left={['60%', null, '64%']}
        position="absolute"
        top="33%"
        width="110px"
        _after={{
          content: '""',
          width: '10px',
          height: '10px',
          position: 'absolute',
          top: '26px',
          backgroundColor: 'var(--colors-brandBlue600)',
          transform: 'rotate(45deg)',
          borderBottom: '1px solid var(--colors-blue700)',
          borderRight: '1px solid var(--colors-blue700)',
        }}
      >
        <StarFourWay color="white" size={12} />
        <Text color="neutrals0" fontWeight="medium" marginLeft="8px" paddingTop="2px">
          Exclusive
        </Text>
      </Flex>
      <Flex
        aria-hidden
        backgroundColor="neutrals0"
        border="0.85px solid rgba(0, 0, 0, 0.04)"
        borderRadius="roundedSmooth"
        bottom="5%"
        boxShadow="0px 10.2px 20.4px 0px rgba(0, 0, 0, 0.08)"
        data-aos="fade-up"
        data-aos-anchor="#exclusive-map-pin-animate"
        data-aos-anchor-placement="top-bottom"
        data-aos-delay="500"
        data-aos-duration="750"
        height={['18%', null, '158px']}
        padding={['8px', null, '16px']}
        position="absolute"
        width={['115%', null, '520px']}
        // minWidth={['327px', null, 'unset']}
        minHeight={['104px', null, 'unset']}
      >
        <ResponsivePicture
          alt="Screenshot of the mobile view of browsing a map of homes for sale"
          borderRadius="roundedSmooth"
          height="100%"
          src="https://images.opendoor.com/source/s3/imgdrop-production/2024-02-1707176950928-36610.png?preset=square-2048"
        />
        <Flex flexDirection="column" marginLeft="24px">
          <Flex
            alignItems="center"
            color="brandBlue600"
            fontSize={['20px', null, '32px']}
            fontWeight="medium"
            letterSpacing="-2.8"
            lineHeight="110"
            marginTop="8px"
          >
            $370,000
            <Box
              as="span"
              color="warmgrey700"
              fontSize={['18px', null, '24px']}
              fontWeight="regular"
              letterSpacing="-2.4"
              lineHeight="112"
              marginLeft="12px"
              textDecoration="line-through"
            >
              $422,000
            </Box>
          </Flex>
          <Text size="130" letterSpacing="-2.4" lineHeight="120" marginTop="8px">
            Carrollton, TX · 5bd / 3ba
          </Text>
          <Text
            color="warmgrey800"
            size="130"
            letterSpacing="-1.4"
            lineHeight="120"
            marginTop={['8px', null, '24px']}
          >
            Added just now · 0 views
          </Text>
        </Flex>
      </Flex>
      <Box aria-hidden id="exclusive-map-pin-animate" />
    </Flex>
  );
};
